.red_text_detail {
  color: red !important;
}

.detail_red {
  color: red !important;
}
.floating_bullets {
  padding-left: 0%;
}
.color_underline {
  padding: 10px 0px 0px 20px;
}
.last_div_care {
  padding: 0px 4%;
}
.last_care_para {
  color: white !important;
}
.last_care_para1 {
  color: #fddb31 !important;
  padding: 0% 1%;
}
.new_row_servicies_detail {
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
}

.vescularContactDiv {
  width: 85%;
  margin: 0 auto;
  padding: 2% 3%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
  top: 16%;

  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.vescularContactDiv > p {
  margin-bottom: 0 !important;
}

.content_div {
  margin: 0% auto;
}
.sec_backgpourd_style {
  padding: 2% 0%;
}
.sec_title {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-transform: capitalize;
  color: #3c3c3c;
}

.sec_para {
  font-weight: 400;
  font-size: 18px;
  line-height: 141%;
  color: #616161;
  margin-bottom: 3%;
}

.text_span {
  text-decoration: underline;
}

.sec_bg {
  background-color: #f8f9fa;
  width: 100%;
}
.dtl_list_mob span {
  position: relative;
  padding-left: 11px;
}

.dtl_list_mob {
  list-style-type: none !important;
  padding-left: 0% !important;
}

.dtl_list::marker {
  content: url("../../../public/imgs/Tick.png");
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 21px !important;
}

.ticker_img {
  height: 12px;
  width: 12px;
}

.sec_list {
  padding-left: 3% !important;
}
.sec_list ::marker {
  color: #fddb31;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 21px !important;
}

.floating_bullets {
  list-style-position: inside;
  padding-left: 0% !important;
}

.floating_bullets ::marker {
  content: "";
  color: transparent;
  font-size: 18px;
  line-height: 21px;
}

.sec_list_item {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #616161;
  padding-bottom: 5px;
}

.last_dtl_sec {
  background-image: url("../Assets/images/we_care_for_you_section_bg_2x.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
}

.last_dtl_sec_mob {
  background-image: url("../Assets/images/we_care_for_you_section_bg_mbl_2x.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;
}

.txt_clr_white {
  color: #ffffff;
}

.txt_clr_yellow {
  color: #fddb31;
}

.floating_img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.dtl_bg_row {
  background-image: url("../../../public/imgs/Rectangle\ 4652.png");
  background-position: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.dtl_bg {
  background-color: aqua;
}

.consult_btn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #3c3c3c;
  border: 0;

  background: #fddb31;
}

.consult_btn_mob {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #3c3c3c;
  border: 0;
  background: #fddb31;
}

.dtl_arr_col {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  max-width: 500px;
}
.zebra_sec_bullets {
  padding-top: 5%;
}

.services_Detail_content {
  max-width: 1160px;
  width: 100%;
  margin: 0% auto;
  display: flex;
}
.services_Detail_content1 {
  max-width: 1160px;
  width: 100%;
  margin: 2% auto;
  display: flex;
  flex-direction: row-reverse;
}
.services_Detail_content_text {
  padding-right: 2%;
  padding-left: 1%;
}
@media screen and (max-width: 600px) {
  .dtl_arr_col {
    max-width: 90%;
  }
  .gray_img {
    background-image: url("../Assets/images/we_care_for_you_section_bg_mbl_2x.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;
  }
}

.dtl_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #3c3c3c;
}

.dtl_list {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  padding-left: 4%;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 0px;
}
.dtl_list p {
  margin-bottom: 5px;
  color: #6b6b6b;
}

@media screen and (max-width: 600px) {
  .ps_div {
    padding: 0% 9%;
  }
}

@media screen and (min-width: 992px) {
  .dtlSurgery_img {
    height: 120px;
    max-width: 195px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .dtlSurgery_img {
    height: 80px;
    max-width: 150px;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .dtl_title {
    font-size: 18px;
  }

  .dtl_list {
    font-size: 12px;
  }

  .dtlSurgery_img {
    height: 80px;
    max-width: 150px;
  }
}

@media screen and (min-width: 1200px) {
  .consult_btn {
    font-size: 14px;
    width: 38%;
    border: 0;

    height: 46px;
    border-radius: 5px;
  }
  .dtl_sec_img {
    width: 100%;
    height: 100%;
  }
  .dtl_sec2_img {
    width: 530px;
    height: 100%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .dtl_sec_img {
    max-width: 100%;
    height: 100%;
  }
  .dtl_sec2_img {
    max-width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .dtl_sec_img {
    max-width: 100%;
    height: -webkit-fill-available;
  }
  .sec_title {
    font-size: 28px;
  }
  .sec_para {
    font-size: 16px;
  }
  .sec_list_item {
    font-size: 16px;
  }
  .dtl_sec2_img {
    max-width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
  .dtl_sec_img {
    max-width: 100%;
    height: -webkit-fill-available;
  }
  .dtl_sec2_img {
    max-width: 100%;
    height: -webkit-fill-available;
  }
  .sec_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin-top: 10px;
    color: #3c3c3c;
  }
  .sec_para {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #616161;
    margin-bottom: 3%;
  }
  .sec_list_item {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

@media screen and (min-width: 375px) and (max-width: 425px) {
  .dtl_sec_img {
    max-width: 100%;
    object-fit: contain;
  }
  .dtl_sec2_img {
    max-width: 100%;
    object-fit: contain;
  }
  .sec_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin-top: 10px;
    color: #3c3c3c;
    margin-bottom: 2%;
  }
  .sec_para {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #616161;
    margin-bottom: 2%;
  }
  .sec_list_item {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .dtl_sec_img {
    max-width: 100%;
    object-fit: contain;
  }
  .dtl_sec2_img {
    max-width: 100%;
    object-fit: contain;
  }
  .sec_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin-top: 10px;
    color: #3c3c3c;
    margin-bottom: 1%;
  }
  .sec_para {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #616161;
    margin-bottom: 1%;
  }
  .sec_list_item {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}

@media screen and (min-width: 850px) and (max-width: 1200px) {
  .consult_btn {
    font-size: 12px;
    width: 45%;
    height: 38px;
    border: 0;

    border-radius: 5px;
  }
}

@media screen and (min-width: 700px) and (max-width: 850px) {
  .consult_btn {
    font-size: 10px;
    width: 48%;
    border: 0;

    height: 32px;
    border-radius: 5px;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .consult_btn {
    font-size: 10px;
    width: 60%;
    border: 0;

    height: 32px;
    border-radius: 5px;
  }
}

@media screen and (min-width: 425px) {
  .consult_btn_mob {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    width: 223px;
    height: 46px;
    border: 0;
    border-radius: 5px;
  }
  .dtlSurgery_img_mob {
    height: 80px;
    max-width: 150px;
  }
  .dtl_title_mob {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }
  .dtl_list_mob {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #616161;
    line-height: 130%;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .consult_btn_mob {
    font-size: 16px;
    width: 223px;
    border: 0;
    height: 40px;
    border-radius: 5px;
  }
  .dtlSurgery_img_mob {
    height: 80px;
    max-width: 150px;
  }
  .dtl_title_mob {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
  }
  .dtl_list_mob {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #616161;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .consult_btn_mob {
    font-size: 14px;
    width: 210px;
    height: 40px;
    border: 0;

    border-radius: 5px;
  }
  .dtlSurgery_img_mob {
    height: 80px;
    max-width: 150px;
  }
  .dtl_title_mob {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
  }
  .dtl_list_mob {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #616161;
    padding-bottom: 2%;
  }
}

/* ***************************************************************************************** */

.LaparoscopicdivImage {
  background-image: url("../Assets/images/surgical_approach_2x.webp");
  padding: 35% 5%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;

  z-index: -1;
  width: 90%;
  margin: 0px auto;
  margin-top: -10px;
  border-radius: 10px;
}

.services_third_desc_heading {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #3c3c3c;
}

.EndocrinedivImage {
  background-image: url("../Assets/images/endocrine_care_desktop_2x.webp");
  padding: 47% 5%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  width: 90%;
  margin: 0px auto;
  margin-top: -20px;
  border-radius: 10px;
}

.VasculardivImage {
  background-image: url("../Assets/images/peripheral_vascular_surgery_1_2x.webp");
  padding: 47% 5%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  width: 90%;
  margin: 0px auto;
  margin-top: -20px;
  border-radius: 10px;
}

.BariatricdivImage {
  background-image: url("../Assets/images/services/LayoutImages/treat_stop_section_1_2x.webp");
  padding: 47% 5%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  width: 90%;
  margin: 0px auto;
  margin-top: -20px;
  border-radius: 10px;
}

.ncologicaldivImage {
  background-image: url("../Assets/images/oncological_surgery_4_1_2x.webp");
  padding: 47% 5%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  width: 90%;
  margin: 0px auto;
  margin-top: -20px;
  border-radius: 10px;
}

.HealthcaredivImage {
  background-image: url("../Assets/images/services/LayoutImages/our_priority_section_1_2x.webp");
  padding: 47% 5%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  width: 90%;
  margin: 0px auto;
  margin-top: -20px;
  border-radius: 10px;
}

.services_subTitle {
  color: white;
}

.services_image_box {
  padding: 0% 5%;
  padding-top: 0%;
  padding-top: 10%;
}

.services_image_text_container {
  position: relative;
  bottom: 48px;
}

.services_detail_body {
}

@media screen and (max-width: 600px) {
  .mob_padding_div {
    padding: 0% 6%;
  }
}

.services_image_text {
  border-radius: 3%;
  background-color: #6b6b6b;
  padding: 5% 5%;
}

.services_image_text_heading {
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #ffffff;
}

.services_image_text_border {
  border: 2px solid #fddb31;
  width: 37%;
  margin: 16px 0px 16px 0px;
}

.services_image_text_des {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.services_page_text_sub {
  color: #fddb31;
  font-size: 20px;
  font-weight: 700;
}

.services_page_text_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  color: #3a3a3a;
}

.services_page_text_des {
  padding-bottom: 5%;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin-top: 10px;

  color: #616161;
}

.services_second_section {
  padding-top: 9%;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  color: #3c3c3c;
}

.services_second_section_heading {
  color: #555555;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #555555;
}

.services_second_section_desc {
  color: #3c3c3c;
  font-weight: 400;
  font-size: 16px;
  line-height: 147.4%;
}

.read_button {
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  font-size: 14px;
  padding: 2% 6%;
}

.read_button :hover {
  background-color: #e2c11c;
}

.read_more_button {
  text-align: center;
  padding: 12% 10%;
}

.read_more_button :hover {
  background-color: #e2c11c;
}

.services_third {
  padding: 10% 7%;
}

.spaceing {
  padding: 0%;
}

.services_third_heading {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  color: #3c3c3c;

  padding-bottom: 5%;
}

.services_third_desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 147.4%;
  color: #3c3c3c;
}

.services_third_image {
  padding-top: 0%;
  width: 90%;
  margin: 0px auto;
}

.services_third_image > img {
  width: 100%;
  border-radius: 15px;
  object-fit: contain;
}

.detailpage_btn {
  border: none !important;
  outline: none;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  font-family: "Roboto";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 30px;

  background: #fddb31;
  border-radius: 5px;
  margin: 40px auto;
}

.detailpage_btn :hover {
  background-color: #e2c11c;
}

.destop_servicesdetailpage_page {
  display: none;
}

@media screen and (min-width: 600px) {
  .LaparoscopicBannerImage {
    background-image: url("../../../src/component/Assets/images/_advance_laparoscopic_surgery_desktop_banner_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }

  .BariatricBannerImage {
    background-image: url("../../../src/component/Assets/images/_laparoscopic_bariatric_surgery_banner_desktop_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }

  .EndocrineBannerImage {
    background-image: url("../../../src/component/Assets/images/endocrine_surgery_desktop_banner_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }

  .OncologicalBannerImage {
    background-image: url("../../../src/component/Assets/images/cancer_surgery_banner_desktop_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }

  .VascularBannerImage {
    background-image: url("../../../src/component/Assets/images/_vascular_surgery_banner_desktop_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }

  .HealthcareBannerImage {
    background-image: url("../../../src/component/Assets/images/women_s_healthcare_clinic_desktop_banner_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }

  .about_heading {
    font-size: 30px;
  }

  .destop_servicesdetailpage_page {
    display: block;
  }

  .mobile_servicesdetail_page {
    display: none;
  }

  .destop_detaikl_para {
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    padding: 12px 0px;
    color: #3c3c3c;
  }

  .destop_group_img {
    display: flex;
    max-width: 1500px;
    width: 100%;
    margin: -12px auto;
    padding: 10px 8%;
  }

  .services_image_box {
    width: 50%;
    height: 350px;
  }

  .destop_deatilpage_heading {
    width: 70%;
    height: 430px;
  }

  .services_image_box {
    padding: 0% 0%;
    padding-top: 0%;
    padding-top: 0%;
    margin-top: -20px;
  }

  .services_image_text_container {
    position: relative;
    bottom: 51px;

    margin-left: -5px;
  }

  .LaparoscopicdivImage {
    padding: 0% 0%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    height: 340px;
    width: 100%;
    margin-top: 0px;
  }

  .VasculardivImage {
    padding: 0% 0%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    height: 340px;
    width: 100%;
    margin-top: 0px;
  }

  .EndocrinedivImage {
    padding: 0% 0%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    height: 340px;
    width: 100%;
    margin-top: 0px;
  }

  .ncologicaldivImage {
    padding: 0% 0%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    height: 340px;
    width: 100%;
    margin-top: 0px;
  }

  .BariatricdivImage {
    padding: 0% 0%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    height: 340px;
    width: 100%;
    margin-top: 0px;
  }

  .HealthcaredivImage {
    padding: 0% 0%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    height: 340px;
    width: 100%;
    margin-top: 0px;
  }

  .services_image_text {
    padding: 12% 5%;
    height: auto;
  }

  .services_image_text_heading {
    font-size: 2.5vw;
  }

  .services_image_text_des {
    font-size: 2vw;
  }

  .services_page_text_heading {
    max-width: 1500px;
    width: 100%;
    margin: 0px auto;
    padding: 0px 8%;
    padding-top: 0%;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
  }

  .services_page_text_des {
    max-width: 1500px;
    width: 100%;
    padding: 1% 8%;
    margin: 0px auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #616161;
    padding-bottom: 3%;
  }

  .destop_box_services_detail {
    display: flex;
    max-width: 1500px;
    width: 100%;
    margin: 30px auto;
    padding: 0px 8%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .destop_detailpage_div1 {
    display: flex;
    justify-content: center;
    max-width: 1500px;
    width: 100%;
    margin: 60px auto;
    padding: 0px 8%;
  }

  .destop_detailpage_div2 {
    display: flex;
    justify-content: center;
    max-width: 1500px;
    width: 100%;
    flex-direction: row-reverse;
    margin: 60px auto;
    padding: 5% 8%;
  }

  .destop_detailpage_div2_full {
    background-color: #f8f9fa;
  }

  .destop_detailpage_div3 {
    display: flex;
    justify-content: center;
    max-width: 1500px;
    width: 100%;
    margin: 60px auto;
    padding: 0px 8%;
  }

  .services_third_image {
    padding-top: 0%;
    width: 50%;
    margin: 0px 10px;
  }

  .services_third_image > img {
    max-width: 468px;

    border-radius: 15px;
    object-fit: contain;
  }

  .destop_Deatilpage_text {
    width: 50%;
    margin: 0px 10px;
  }

  .services_detail_body {
    padding: 0% 0%;
  }

  .servicesdetail_box_description {
    font-weight: 400;
    font-size: 22px;
    line-height: 147.4%;
    color: #3c3c3c;
    padding-top: 3%;
  }

  .detailpage_btn_desktop {
    padding: 10px 20px;
    left: 278px;
    top: 1307px;
    background: #fddb31;
    border-radius: 5px;
    text-align: center;
    display: flex;
    border: none;
    outline: none;
    justify-content: center;
    margin: 20px auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    font-family: "Roboto";
  }

  .detailpage_btn_desktop:hover {
    background-color: #e2c11c;
  }

  .services_second_section {
    padding-top: 0%;
  }

  .services_second_section {
    font-size: 20px;
    line-height: 28px;
  }

  .services_second_section_heading {
    font-size: 15px;
    line-height: 12px;
  }

  .services_third_heading {
    font-size: 20px;
    line-height: 28px;
  }

  .services_third_desc {
    font-size: 15px;
    line-height: 18px;
  }

  .destop_Deatilpage_text {
    width: 50%;
    margin: 0px 10px;
    padding-right: 5%;
  }
}

@media screen and (min-width: 1000px) {
  .services_image_text_heading {
    font-weight: 600;
    font-size: 36px;
    max-width: 25ch;
    line-height: 35px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .about_heading {
    font-size: 48px;
  }

  .services_image_text_des {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;

    color: #ffffff;
  }

  .services_image_box {
    height: auto;
  }

  .LaparoscopicdivImage {
    height: 80% !important;
  }

  .services_image_text {
    padding: 8% 7%;
    height: auto;
  }

  .destop_deatilpage_heading {
    height: auto;
  }

  .LaparoscopicdivImage {
    height: 80%;
  }

  .VasculardivImage {
    height: 80%;
  }

  .EndocrinedivImage {
    height: 80%;
  }

  .ncologicaldivImage {
    height: 80%;
  }

  .BariatricdivImage {
    height: 80%;
  }

  .HealthcaredivImage {
    height: 85%;
  }

  .services_second_section {
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    text-transform: capitalize;
    color: #3c3c3c;
  }

  .services_second_section {
    padding-top: 0%;
  }

  .services_second_section_heading {
    font-size: 22px;
    line-height: 26px;
    font-weight: 400;
  }

  .services_second_section_heading_1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
  }

  .services_third_desc {
    font-size: 22px;
    line-height: 26px;
  }

  .services_second_section_desc {
    font-size: 22px;
    line-height: 26px;
  }

  .services_third_heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    text-transform: capitalize;
    color: #3c3c3c;
  }
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
  .dtl_arr_col {
    max-width: 48% !important;
  }

  .dtl_title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }

  .dtl_list {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    padding-left: 4%;
    font-size: 12px;
    line-height: 130%;
  }

  .dtl_list::marker {
    font-size: 12px;
  }
}

@media screen and (max-width: 350px) {
  .chips > .row > * {
    padding-left: 10px !important;
  }
}

@media screen and (max-width: 600px) {
  .chips:last-child {
    margin-bottom: 0px !important;
  }
}
