.faq_mob_banner {
  display: block;
}

.main_heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #3c3c3c;
  padding: 50% 8%;
  margin-bottom: 0px;
}

.detail_page {
  display: none;
}
.publish {
  display: flex;
  justify-content: space-between;
}

.publist_date {
  display: flex;
  align-items: center;
}

.publish_by {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog_data img {
  width: 100%;
  height: 100%;
}

.blog_para2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.readmore_btn {
  background: #fddb31;
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 131px;
  height: 42px;
  color: #3c3c3c;

  margin: 0px auto;
}
.readmore_btn:hover {
  background-color: #e2c11c;
}
.readmore_icon {
  margin-left: 14px;
}
.readmore_btn img {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.paginationBttns {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0px !important;
  margin-bottom: 0px;
}

.paginationBttns li {
  height: 30px;
  width: 30px;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 120px;
  height: 30px;
  width: 30px;
  color: #000000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationActive a {
  background-color: #383838;
  color: #fddb31 !important;
}

.tag_div {
  display: flex;
  background: #f2f2f2;
  border-radius: 23px;
  justify-content: space-around;
  align-items: center;
  width: 30% !important;
  padding: 10px 5px;
  margin: 10px 1px;
}

.tag_div p {
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 0px;
}

.close_icon {
  width: 18px;
  height: 18px;
  background: #9a9a9a;
  fill: #f2f2f2;
  border-radius: 120px;
  font-size: 12px;
}

.tag_name {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tag_head {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 8%;
  color: #3c3c3c;
}

.select_vlaue_blog {
  border-bottom: 1px solid #3c3c3c !important;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3c3c3c;
  margin-left: 17px;
  margin-right: 5px;
  background-color: transparent;
}

.select_vlaue_blog:focus-visible {
  border-bottom: 1px solid #3c3c3c !important;
  border: none;
  outline: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3c3c3c;
  margin-left: 17px;
  margin-right: 5px;
  background-color: transparent;
}

@media only screen and (min-width: 600px) {
  .faq_mob_banner {
    display: block;
  }

  .detail_page {
    display: block;
  }
}
@media only screen and (min-width: 600px) {
  .main_heading {
    display: none;
  }
  .blog_para2 {
    margin: 0px auto;
    margin-left: 20px;
  }
  .tag {
    width: 100%;
    padding: 0px 0%;
  }

  .blog_data img {
    width: 40% !important;
    margin-right: 20px;
  }

  .tag_name {
    margin: 0px auto;
  }

  .tag_div {
    display: flex;
    background: #f2f2f2;
    border-radius: 23px;
    justify-content: space-around;
    align-items: center;
    width: 40% !important;
    padding: 10px 5px;
    margin: 10px 1px;
  }

  .tag_head {
    max-width: 1500px !important;
    margin: 0px auto !important;
    padding: 20px 0% !important;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }

  .blog {
  }
}

@media only screen and (min-width: 1100px) {
  .blog_main {
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .main_heading {
    display: none;
  }

  .blog_para2 {
    margin: 0px auto;
    margin-left: 20px;
  }

  .tag_div {
    display: flex;
    background: #f2f2f2;
    border-radius: 23px;
    justify-content: space-around;
    align-items: center;
    width: 16% !important;
    padding: 10px 5px;
    margin: 10px 1px;
  }

  .tag_head {
    margin: 20px auto !important;
    font-size: 24px;
    line-height: 24px;
    color: #3c3c3c;
  }

  .tag_name {
    margin: 0px auto;
  }
}

@media only screen and (max-width: 440px) {
  .tag_div p {
    font-size: 2.5vw;
  }
}

/* ************************************************************************************** */

/* BLOGS PAGE CSS (MUBEEN) */

@media screen and (min-width: 768px) {
  .blog_value {
    display: flex;
    width: 60%;
    align-items: baseline;
    justify-content: flex-start;
  }
  .blog_main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    padding: 0px 0%;
  }
  .blog {
    padding-top: 45px;
  }
  .destop_blog {
    border: 1px solid #d9d9d9;
    box-shadow: 4px 4px 15px rgba(30, 30, 30, 0.05);
    border-radius: 10px;
  }
  .blog_img {
    height: 157px;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
  }
  .publish_by img {
    height: 11px;
    width: 11px;
    margin-right: 8px;
  }
  .destop_pagination_view {
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    padding: 30px 0px 54px;
  }
  .publish {
    padding: 12px 15px 10px;
  }
  .publist_date img {
    width: 13px;
    height: 13px;
    margin-right: 7px;
  }
  .publist_date p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
  .publish_by p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
  .blog_data {
    padding: 0px 15px 0px;
  }
  .blog_heading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: #3c3c3c;
    cursor: pointer;
    margin-bottom: 6px;
  }
  .blog_heading:hover {
    color: #fddb31;
  }
  .blog_para {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #616161;
    margin-bottom: 0px;
    max-width: 50ch;
  }
  .blog_btn {
    padding: 15px 15px 13px;
    text-align: end;
  }

  .blog_value p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1125px) {
  .blog_main {
    max-width: 1180px;
    gap: 30px;
    width: 100%;
    margin: 0px auto;
  }
  .destop_blog {
    width: 350px;
  }
  .destop_pagination_view {
    width: 1110px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .blog_main {
    width: 980px;
    column-gap: 10px;
    row-gap: 30px;
  }
  .destop_blog {
    width: 300px;
  }
  .destop_pagination_view {
    width: 980px;
    padding: 2% 5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .blog_main {
    width: 760px;
    column-gap: 5px;
    row-gap: 30px;
  }
  .destop_blog {
    width: 250px;
  }
  .destop_pagination_view {
    width: 760px;
  }
}

@media screen and (max-width: 767px) {
  .blog_main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 0%;
  }
  .destop_pagination_view {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding: 25px 0px 58px;
  }
  .paginationBttns {
    justify-content: center;
  }
  .blog_value {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }
  .blog {
    padding-top: 25px;
  }
  .destop_blog {
    border: 1px solid #d9d9d9;
    box-shadow: 4px 4px 15px rgba(30, 30, 30, 0.05);
    border-radius: 10px;
  }
  .publish_by img {
    height: 11px;
    width: 11px;
    margin-right: 8px;
  }

  .publish {
    padding: 12px 15px 10px;
  }
  .publist_date img {
    width: 13px;
    height: 13px;
    margin-right: 7px;
  }
  .publist_date p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
  .publish_by p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
  .blog_data {
    padding: 0px 15px 9px;
  }
  .blog_heading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: #3c3c3c;
    cursor: pointer;
    margin-bottom: 6px;
  }
  .blog_heading:hover {
    color: #fddb31;
  }
  .blog_para {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #616161;
    margin-bottom: 0px;
    max-width: 50ch;
  }
  .blog_btn {
    padding: 0px 15px 13px;
    text-align: end;
  }

  .blog_value p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .blog_img {
    width: 388px;
    height: 174px;
    border-radius: 10px 10px 0px 0px;
  }

  .blog_main {
    width: 388px;
    margin: 0px auto;
    gap: 21px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .blog_img {
    width: 365px;
    height: 174px;
    border-radius: 10px 10px 0px 0px;
  }

  .blog_main {
    width: 365px;
    margin: 0px auto;
    gap: 21px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .blog_img {
    width: 310px;
    height: 174px;
    border-radius: 10px 10px 0px 0px;
  }

  .blog_main {
    width: 310px;
    margin: 0px auto;
    gap: 21px;
  }
}

/* ********************************************************************************************************** */

/* BLOGS DETAIL PAGE CSS (MUBEEN) */

@media screen and (min-width: 768px) {
  .share img {
    width: 37px;
    height: 35px;
    margin-right: 15px;
  }
  .share p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 0px;
    margin-right: 24px;
  }
  .destop_text {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 40px;
    margin-bottom: 45px;
  }
  .destop_blog_img_head {
    height: 385px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
  }
  .blog_heading1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
    margin-bottom: 20px;
  }
  .blog_para1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 145%;
    color: #616161;
    margin-bottom: 0px;
  }
  .share {
    display: flex;
    align-items: center;
    padding: 30px 0px 60px;
  }
}

@media screen and (min-width: 1125px) {
  .main_heading1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
  .main_heading1:before {
    content: "Blogs >";
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #fddb31;
  }
  .blog_main1 {
    width: 1110px;
    margin: auto;
  }
  .blog_img1 {
    width: 815px;
    height: 385px;
    border-radius: 15px;
  }
  .blogdataimg {
    width: 445px;
    height: 208px;
  }
  .publist_date_blog_detail img {
    height: 26px;
    width: 26px;
    margin-right: 7px;
  }
  .publish_by_blog_detail img {
    width: 22px;
    height: 22px;
    margin-right: 9px;
  }
  .publish_blog_detail {
    gap: 30px;
  }
  .publish_blog_detail p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
  .blog_detail_top_div {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .main_heading1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
  .main_heading1:before {
    content: "Blogs >";
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #fddb31;
  }
  .blog_main1 {
    width: 980px;
    margin: auto;
  }
  .blog_img1 {
    width: 815px;
    height: 385px;
    border-radius: 15px;
  }
  .blogdataimg {
    width: 445px;
    height: auto;
  }
  .publist_date_blog_detail img {
    height: 26px;
    width: 26px;
    margin-right: 7px;
  }
  .publish_by_blog_detail img {
    width: 22px;
    height: 22px;
    margin-right: 9px;
  }
  .publish_blog_detail {
    gap: 30px;
  }
  .publish_blog_detail p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
  .blog_detail_top_div {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .main_heading1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
  .main_heading1:before {
    content: "Blogs >";
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #fddb31;
  }
  .publist_date_blog_detail img {
    height: 20px;
    width: 20px;
    margin-right: 7px;
  }
  .publish_by_blog_detail img {
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }
  .publish_blog_detail {
    gap: 30px;
  }
  .publish_blog_detail p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #3c3c3c;
    margin-bottom: 0px;
  }
  .blog_detail_top_div {
    margin-bottom: 20px;
  }
  .blog_main1 {
    width: 750px;
    margin: auto;
  }
  .blog_img1 {
    width: 710px;
    height: 385px;
    border-radius: 15px;
  }
  .blogdataimg {
    width: 445px;
    height: auto;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .blog_heading1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #3c3c3c;
    margin-bottom: 10px;
  }
  .publist_date_blog_detail img {
    height: 9.75px;
    width: 9.75px;
    margin-right: 8px;
  }
  .publish_by_blog_detail img {
    width: 11px;
    height: 11px;
    margin-right: 9px;
  }
  .publish_blog_detail {
    gap: 0px;
    justify-content: space-between;
  }

  .blog_main1 {
    width: 388px;
    margin: 0px auto;
  }
  .blog_img1 {
    width: 388px;
    height: 183px;
    border-radius: 10px;
  }
  .blogdataimg {
    width: 388px;
    height: 183px;
  }
  .main_heading1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #3c3c3c;
    margin-bottom: 17px;
  }
  .main_heading1:before {
    content: "Blogs >";
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #fddb31;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .blog_heading1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #3c3c3c;
    margin-bottom: 10px;
  }
  .blog_main1 {
    width: 365px;
    margin: 0px auto;
    padding: 2% 5%;
  }
  .blog_img1 {
    width: 100%;
    height: 183px;
    border-radius: 10px;
  }
  .blogdataimg {
    width: 100%;
    height: 183px;
    border-radius: 10px;
  }
  .main_heading1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #3c3c3c;
    margin-bottom: 17px;
  }
  .main_heading1:before {
    content: "Blogs >";
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #fddb31;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .blog_heading1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #3c3c3c;
    margin-bottom: 10px;
  }
  .blog_main1 {
    width: 310px;
    margin: 0px auto;
    padding: 2% 5%;
  }
  .blog_img1 {
    width: 100%;
    height: 183px;
    border-radius: 10px;
  }
  .blogdataimg {
    width: 100%;
    height: 183px;
    border-radius: 10px;
  }
  .main_heading1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #3c3c3c;
    margin-bottom: 17px;
  }
  .main_heading1:before {
    content: "Blogs >";
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #fddb31;
  }
}

@media screen and (max-width: 767px) {
  .publist_date_blog_detail img {
    height: 9.75px;
    width: 9.75px;
    margin-right: 8px;
  }
  .publish_by_blog_detail img {
    width: 11px;
    height: 11px;
    margin-right: 9px;
  }
  .publish_blog_detail {
    gap: 0px;
    justify-content: space-between;
  }
  .share {
    display: flex;
    align-items: center;
    padding: 21px 0px 56px;
  }
  .share img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .share p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 0px;
    margin-right: 15px;
  }
  .blog_detail_top_div {
    margin-bottom: 15px;
  }
  .publish_blog_detail p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;

    margin-bottom: 0px;
  }

  .destop_blog_img_head {
    margin-bottom: 25px;
  }

  .blog_para1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #616161;
    margin-bottom: 0px;
  }
  .destop_text {
    margin-top: 30px;
  }
  .blogdataimg {
    margin-bottom: 20px;
  }
  .margin_btm {
    margin-bottom: 20px;
  }
}
