.mbn_main2 {
  background-image: url("../../../public/imgs/doctor_section_background_2x.webp");
  border-radius: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  max-width: 1110px;
  margin: 0% auto;
}

.prof_main_div {
  display: flex;
  column-gap: 18px;
  width: 1110px;
  align-items: center;
}

.Right_div {
  z-index: 1;
  width: 414px;
  margin-right: 62px;
}
.profile_right {
  width: 100%;
  height: 100%;
}
.practice_div {
  margin-top: 20px;
  margin-bottom: 15px;
}

.practice_img_div {
  column-gap: 20px;
  position: absolute;
  width: 1110px;
  background-color: #e7e7e7;
}

.practice_img_L {
  height: 70px;
  width: 145px;
}

.practice_img_R {
  height: 73px;
  width: 186px;
}

.prof_name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: #fddb31;
  margin-bottom: 0%;
}

.prof_name_detail {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 0%;
  max-width: 58ch;
}

.second_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #fddb31;
  margin-bottom: 0%;
}

.second_detail {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 0%;
}

.practice_letter {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #fddb31;
  margin-bottom: 12px;
}

.profile_btn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3c3c3c !important;
  padding: 15px 30px;
  border: 0px;
  background-color: #fddb31;
  border-radius: 5px;
  margin-bottom: 30px;
}

.prof_div {
  margin-top: 33px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1125px) {
  .extra_padding {
    padding-left: 95px;
    padding-top: 15px;
  }
  .profile_button_div {
    /* padding-left: 165px; */
    padding-top: 30px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .mbn_main2 {
    width: 980px;
  }
  .prof_main_div {
    width: 980px;
    column-gap: 5px;
    align-items: center;
  }
  .extra_padding {
    padding-left: 40px;
    padding-top: 15px;
  }
  .Right_div {
    margin-right: 30px;
  }
  .practice_img_div {
    width: 980px;
  }
  .profile_button_div {
    /* padding-left: 105px; */
    padding-top: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .mbn_main2 {
    width: 755px;
  }
  .prof_main_div {
    width: 755px;
    column-gap: 5px;
    align-items: center;
  }
  .extra_padding {
    padding-left: 20px;
    padding-top: 15px;
  }
  .Right_div {
    margin-right: 10px;
  }
  .practice_img_div {
    width: 755px;
  }
  .prof_name {
    font-size: 28px;
  }
  .prof_div {
    margin-top: 15px;
  }
  .second_heading {
    font-size: 20px;
  }
  .practice_letter {
    font-size: 20px;
  }
  .profile_btn {
    margin-bottom: 15px;
  }
  .profile_right {
    height: 100%;
  }
  .profile_button_div {
    /* padding-left: 85px; */
    padding-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .practice_img_div {
    display: none !important;
  }
  .prof_main_div {
    flex-direction: column-reverse;
  }
  .mbn_main2 {
    background-image: url("../Assets/images/services/doctor_section_background_mobile_2x.webp");
    width: 100vw;
    border-radius: 0px;
    background-size: 100% 100%;
  }
  /* .mbn_main {
    background-image: url("../Assets/images/services/mbn_main_mob.png");
    border-radius: 0px;
  } */
  .prof_main_div {
    width: 100%;
    margin: auto;
  }
  .Right_div {
    margin-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
  }
  .profile_right {
    width: 138px;
    height: 138px;
    border-radius: 50%;
  }
  .position_div {
    background-color: #e7e7e7;
    padding: 6px 0px;
    display: flex;
    justify-content: center;
    gap: 18px;
  }
  .profile_button_div {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .profile_btn {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .prof_name {
    font-size: 24px;
    text-align: center;
  }
  .prof_name_detail {
    font-size: 12px;
    text-align: center;
    max-width: 43ch;
    margin: auto;
  }
  .prof_div {
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .second_heading {
    font-size: 18px;
    text-align: center;
  }
  .second_detail {
    font-size: 12px;
    text-align: center;
  }
  .practice_div {
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .practice_letter {
    font-size: 18px;
    text-align: center;
    margin-bottom: 17px;
  }
  .practice_img_L {
    width: 130px;
    height: 62px;
  }
  .practice_img_R {
    width: 156px;
    height: 53px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .prof_name {
    font-size: 22px;
    text-align: center;
  }
  .prof_name_detail {
    font-size: 12px;
    text-align: center;
    max-width: 43ch;
    margin: auto;
  }
  .prof_div {
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .second_heading {
    font-size: 14px;
    text-align: center;
  }
  .second_detail {
    font-size: 12px;
    text-align: center;
  }
  .practice_div {
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .practice_letter {
    font-size: 14px;
    text-align: center;
    margin-bottom: 17px;
  }
  .practice_img_L {
    width: 110px;
    height: 62px;
  }
  .practice_img_R {
    width: 136px;
    height: 53px;
  }
}
