.appointment_mob {
  background-image: url("../Assets/images/appointment_2x.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5% 0%;
  margin: 0px 0px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-left: 0% !important;
}

.appointment {
  max-width: 1500px;
  width: 85%;
  margin: 0px auto;
}

.appoint_para {
  padding: 10% 0%;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #6a7c8a;
}

.form_main_div {
  height: auto;
  background: #ffffff;

  border: 1px solid transparent;
  border-radius: 12px;
}

.appoint_form_div {
  width: 88%;
  margin: 0px auto;
  padding: 5% 0px;
}

.appoint_input__div {
  position: relative;
  height: 52px;
  margin-top: 25px;
  margin-left: 6px;
  margin-bottom: 20px;
}

.date-app {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  position: relative;
  top: 21px;

  color: #000000;
}

.appointment_field {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #6b6b6b;
  border-radius: 5px;
  outline: none;
  padding: 1rem;
  background: none;
  z-index: 1;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3c3c3c;
}

.appoint__label {
  position: absolute;
  left: 10px;
  top: 1rem;
  padding: 0 0.25rem;
  background-color: #f1f2f4;
  z-index: 3;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3c3c3c;

  margin-top: -26px;
}

.appointment_field:focus + .appoint__label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1976d2;
  font-weight: 500;
  z-index: 10;
}

.appointment_field:focus {
  border: 2px solid #1976d2;
}

.appoint_dropdwon {
  width: 99%;
  margin: 0px auto !important;
  margin-left: -3px !important;
}

.date {
  width: 27%;
  margin: 30px 1%;
}

.month {
  width: 40%;
  margin: 30px 1%;
}

.year {
  width: 27%;
  margin: 30px 1%;
}

.appont_select {
  margin-left: 3px !important;
}

.app-p-text {
  padding: 0px 5%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
}

.apapointment_btn {
  background: #fddb31;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 105px;
  height: 46px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  /* margin-left: 10px; */
  color: #383838;
}
.apapointment_btn:hover {
  background: #e2c11c;
  color: #383838;
}

.day_night {
  padding: 0% 5%;
  margin-bottom: 20px;
}

.day_night p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #121212;
}

.radio_label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  margin-left: 10px;
}

.capcha {
  margin-top: 20px;
  position: relative;
}

.size {
  width: 50% !important;
}

.heading_new {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #fddb31;
  text-align: center;
}

.modal-dialog {
  top: 50% !important;
}

.datepicker-navbar {
  display: flex !important;
  justify-content: space-around !important;
  margin-bottom: 0.5em !important;
}

.datepicker.default .datepicker-wheel {
  border-top: 1px solid #fddb31 !important;
  border-bottom: 1px solid #fddb31 !important;
}

.datepicker.default {
  background-color: white !important;
}

.datepicker.default .datepicker-caption-item {
  color: #000000 !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.datepicker.default .datepicker-scroll li {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #000000 !important;
}

.btn {
  width: 139px;
  height: 46px;
  border: 1px solid #6b6b6b;
  border-radius: 5px;
}

.cancelBtn {
  background: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #3c3c3c;
}

.setBtn {
  background: #3c3c3c;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #fddb31;
}

.modal-footer {
  justify-content: space-around !important;
}

.datepicker.default .datepicker-header {
  color: #000000 !important;
}

.datepicker .datepicker-header {
  padding: 1em 2.5em !important;
  min-height: 2em !important;
  line-height: 2em !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: start !important;
}

.datepicker .datepicker-wheel {
  height: 42px !important;
}

.datepicker .datepicker-viewport {
  height: 147px !important;
}

.datepicker .datepicker-navbar-btn {
  width: 139px !important;
  height: 46px !important;
  border: 1px solid #6b6b6b !important;
  border-radius: 5px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  color: #fddb31 !important;
  background: #3c3c3c !important;
}

.datepicker .datepicker-navbar-btn:nth-of-type(2) {
  color: #3c3c3c !important;
  background: #ffffff !important;
}

@media screen and (min-width: 600px) {
  .heading_new {
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
  }
}

@media screen and (min-width: 425px) and (max-width: 600px) {
  .heading_new {
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .heading_new {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
  }
}

@media screen and (min-width: 1125px) {
  .appointment {
    width: 1110px;
    margin: auto;
  }
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .appointment {
    width: 980px;
    margin: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .appointment {
    width: 750px;
    margin: auto;
  }
}

.text-danger {
  font-size: 14px;
  margin-bottom: 8px;
}

@media screen and (min-width: 768px) {
  .text-danger {
    font-size: 16px;
  }
}
