.adv_sur_main {
  background-image: url("../../../public/imgs/Rectangle\ 4559.png");
  background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}

.adv_2nd_div {
  display: flex;
}

.A_div {
  height: 387px;
  width: 357px;
}

@media screen and (min-width: 768px) {
  .A_div {
    padding: 14px;
  }
  .adv_sur_main {
    height: 517px;
    padding: 74px 0px 56px;
  }
}

@media screen and (min-width: 901px) {
  .vance_font {
    font-size: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .vance_font {
    font-size: 40px;
  }
  .A_div {
    height: 345px;
    width: 320px;
    padding: 11px;
  }
}

.A_img {
  height: auto;
  width: 100%;
}

.vance_font {
  color: #cccccc;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 0px !important;
  text-align: center;
  line-height: 0.7;
}

.para_main {
  max-width: 100%;
}

.para_left {
  width: 50%;
}

.para_right {
  width: 50%;
  position: relative;
  left: -5%;
}

.dot_para {
  border: 1px dashed #fddb31;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  padding: 8% 0%;
  color: #fddb31;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .adv_2nd_div {
    height: 257px;
  }
  .para_right {
    width: 50%;
    position: relative;
    left: 0%;
  }

  .A_div {
    align-self: flex-end;
  }
  .vance_font {
    color: #f8f9fa;
    opacity: 0.1;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .mid_para {
    position: relative;
    left: 60%;
  }
  .vance_font {
    font-size: 23px;
  }
  .A_div {
    height: 109px;
    width: 100.11px;
  }
  .dot_para {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    padding: 17px 0px;
    width: 184px;
    margin-bottom: 15px;
  }
  .para_main {
    position: absolute;
    column-gap: 20px;
    left: 5%;
    top: 12%;
  }
  .adv_2nd_div {
    justify-content: center;
    position: relative;
    width: 428px;
    margin: auto;
  }
  .adv_sur_main {
    height: 267px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .mid_para {
    position: relative;
    left: 58%;
  }
  .vance_font {
    font-size: 21px;
  }
  .A_div {
    height: 109px;
    width: 100.11px;
  }
  .dot_para {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    padding: 17px 0px;
    width: 170px;
    margin-bottom: 15px;
  }
  .para_main {
    position: absolute;
    column-gap: 15px;
    left: 2%;
    top: 12%;
  }
  .adv_2nd_div {
    justify-content: center;
    position: relative;
    width: 370px;
    margin: auto;
  }
  .adv_sur_main {
    height: 267px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .mid_para {
    position: relative;
    left: 58%;
  }
  .vance_font {
    font-size: 15px;
  }
  .A_div {
    height: 109px;
    padding-top: 10px;
    width: 90.11px;
  }
  .dot_para {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    padding: 16px 0px;
    width: 147px;
    margin-bottom: 10px;
  }
  .para_main {
    position: absolute;
    column-gap: 10px;
    left: 1%;
    top: 12%;
  }
  .adv_2nd_div {
    justify-content: center;
    position: relative;
    width: 310px;
    margin: auto;
    padding-bottom: 15px;
  }
  .adv_sur_main {
    height: 257px;
  }
}
