.yellowText {
  color: #fddb31;
}
.mob_services_name {
  font-weight: 500;
  font-size: 14px;
  max-width: 9ch;
  margin-left: 3px;
  line-height: 16px;
  text-align: center;
  color: #3c3c3c;
  margin-top: 10px;
}
.header_banner_all {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  width: 100%;
}
.bariatric_title_red {
  color: red;
}
.about_para {
  margin-bottom: 5px !important;
}
.aboutus_head_btn {
  padding: 3% 0px;
}
.services_image_box {
  z-index: 0;
}

.aboutus_head_btn button {
  width: 236px;
  height: 46px;
  outline: none;
  color: #3c3c3c;
  background: #fddb31;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
@media screen and (max-width: 600px) {
  .about_head {
    background-image: url("../../../public/imgs/about_us_mobile_4x.webp");
    background-position: 100% 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 270px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .services_head {
    background-image: url("../Assets/images/our_services_anner_mobile_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 270px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .about_head_blogdetail {
    background-image: url("../Assets/images/blogs_detail_mobile_banner_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 270px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .blog_head_banner {
    background-image: url("../Assets/images/blogs_detail_mobile_banner_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 270px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .contact_us_head {
    background-image: url("../Assets/images/contact_us_banner_desktop_2x.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 270px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .faq_head_banner {
    background-image: url("../Assets/images/faq_s_banner_mobile_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 270px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .termofuse_head_banner {
    background-image: url("../Assets/images/terms_of_use_banner_mobile_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 270px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .privacypolicy_header_banner {
    background-image: url("../Assets/images/privacy_policy_banner_mobile_2x.webp");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 270px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .about_heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    width: 100%;
    color: #fddb31;
    margin-bottom: 5px;
  }
  .aboutus_head_btn {
    padding: 10% 0px;
  }
}
.flexSetting {
  width: 24%;
}
.about_para {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  color: #ffffff;
}
.data_div {
  padding: 7% 7%;
}
.data_div h5 {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  color: #3c3c3c;
}
.data_div h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 146.19%;
  color: #3c3c3c;
  text-transform: uppercase;
}
.data_div p {
  font-weight: 400;
  font-size: 16px;
  color: #616161;
}
.about_box {
  padding: 0% 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about_box1 {
  width: 85%;
  height: 66px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 5px 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.about_box2 {
  background: #3c3c3c;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: 66px;
  padding: 5px 10px;
}

.about_box1 img {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

.about_box2 img {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

.about_box > .row > * {
  padding: 0 !important;
}
@media screen and (min-width: 600px) {
  .about_head {
    background-image: url("../../../public/imgs/about_us_desktop_4x.webp");
    position: relative;
    background-position: 100% 13%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }
  .mob_services_name {
    display: none;
  }
  .about_head_blogdetail {
    background-image: url("../Assets/images/blogs_detail_desktop_banner_2x.webp");
    position: relative;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }
  .services_head {
    background-image: url("../Assets/images/our_services_banner_desktop_2x.webp");
    position: relative;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }
  .blog_head_banner {
    background-image: url("../Assets/images/blogs_detail_desktop_banner_2x.webp");
    position: relative;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }
  .contact_us_head {
    background-image: url("../Assets/images/contact_us_banner_mobile_2x.webp");
    position: relative;
    background-position: 100% 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 4%;
  }
  .faq_head_banner {
    background-image: url("../Assets/images/faq_s_banner_desktop_2x.webp");
    position: relative;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .termofuse_head_banner {
    background-image: url("../Assets/images/terms_of_use_banner_desktop_2x.webp");
    position: relative;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .privacypolicy_header_banner {
    background-image: url("../Assets/images/privacy_policy_banner_desktop_2x.webp");
    position: relative;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 370px !important;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    padding: 5% 6%;
  }
  .new {
  }
  .about_heading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 43px;
    line-height: 50px;
    text-transform: uppercase;
    color: #fddb31;
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
  }
  .about_para {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;

    width: 100%;
    padding: 0px 0%;
    margin: 0px auto;
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
  }
  .about_mob {
    display: flex;
    padding: 100px 5%;
    gap: 85px;
    max-width: 1500px;
    width: 100%;
    margin: 0px auto;
  }
  .about_content_more {
    width: 100%;
  }
  .data_div {
    padding: 0;
  }
  .about_box {
    padding: 0;
    margin: 0;
    flex-direction: column;
  }
  .data_div h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;

    color: #3c3c3c;
  }
  .data_div h6 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    color: #3c3c3c;
    text-transform: uppercase;
  }
  .data_div p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 146.19%;
    color: #616161;
    margin-top: 20px;
  }
  .div-images {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 278.31px);
    grid-template-rows: repeat(2, auto);
    grid-gap: 20px;
    display: none;
  }
  .div-images > img {
    width: 278.31px;
    height: 421px;
  }
  .div-images > img:nth-of-type(3) {
    width: 580px;
    height: 100%;
  }
  .div-images > :nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .div-images > :nth-child(2) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .div-images > :nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .about_box > .row {
    flex-direction: column;
    margin-top: 10px;
    gap: 40px;
    width: 100%;
  }
  .about_box > .row > * {
    padding: 0;
  }

  .about_box1 {
    width: 90px;
    height: 77.7px;
  }
  .about_box2 {
    width: 90px;
    height: 77.7px;
  }
  .flexSetting {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  .flexContent {
    display: flex;
    flex-direction: column;
  }
  .flexContent > :nth-child(1) {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 144.19%;

    color: #3c3c3c;
  }
  .flexContent > :nth-child(2) {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 146.19%;
    color: #616161;
  }
  .carousel-control-prev {
    width: 10%;
  }
  .mission_div {
    width: 100%;
  }
  .circle_progress {
    flex-direction: column;
  }

  .CircularProgressbar {
    width: 16% !important;
  }
  .header_banner_all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 3%;
    width: 100%;
  }
  @media screen and (min-width: 850px) {
    .header_banner_all {
      padding-bottom: 1%;
    }
  }
  @media screen and (min-width: 1200px) {
    .about_heading {
      font-weight: 600;
      font-size: 48px;
      line-height: 118%;
      text-transform: uppercase;
      color: #fddb31;
      width: 100%;
    }
    .about_para {
      font-weight: 400;
      font-size: 22px;
      line-height: 21px;
      color: #ffffff;
      width: 100%;
    }
    .about_mob {
      display: flex;
      padding: 100px 8%;
      gap: 15px;
      max-width: 1500px;
      width: 100%;
      margin: 0px auto;
    }
    .about_content_more {
      width: 100%;
    }
    .data_div {
      padding: 0;
    }
    .about_box {
      padding: 0;
      margin: 0;
      flex-direction: column;
    }
    .data_div h5 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;

      color: #3c3c3c;
    }
    .data_div h6 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      color: #3c3c3c;
    }
    .data_div p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 146.19%;
      color: #616161;
      margin-top: 20px;
    }
    .div-images {
      width: 50%;
      display: grid;
      grid-template-columns: repeat(2, 278.31px);
      grid-template-rows: repeat(2, auto);
      grid-gap: 20px;
    }
    .div-images > img {
      max-width: 250px;
      height: 380px;
    }
    .div-images > img:nth-of-type(3) {
      max-width: 554px;
    }
    .div-images > :nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .div-images > :nth-child(2) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .div-images > :nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .about_box > .row {
      flex-direction: column;
      margin-top: 10px;
      gap: 20px;
    }
    .about_box > .row > * {
      padding: 0;
    }

    .about_box1 {
      width: 75px;
      margin-right: 5px;
    }
    .about_box2 {
      width: 75px;
      margin-right: 5px;
    }
    .flexSetting {
      display: flex;
      gap: 10px;
    }
    .flexContent {
      display: flex;
      flex-direction: column;
      width: 85%;
    }
    .flexContent > :nth-child(1) {
      margin: 0;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 144.19%;
      color: #3c3c3c;
    }
    .flexContent > :nth-child(2) {
      margin: 0;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 146.19%;
      color: #616161;
    }
    .carousel-control-next,
    .carousel-control-prev {
      width: 15%;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .last_screen_between {
    justify-content: space-between;
  }
  .mob_services_name {
    font-size: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 424px) {
  .mob_services_name {
    margin-left: 0px;
  }
}

@media screen and (min-width: 1125px) {
  .about_mob {
    width: 1110px;
    padding: 100px 0px;
  }
  .progress_main {
    width: 1110px !important;
    padding: 0px !important;
  }
  .periorty {
    width: 1110px !important;
    padding: 3% 0% !important;
  }
  .header_banner_all {
    width: 1110px !important;
    margin: auto;
  }
  .destop_group_img {
    width: 1110px !important;
    padding: 10px 0% !important;
  }
  .services_page_text_heading {
    width: 1110px !important;
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
  .services_page_text_des {
    width: 1110px !important;
    padding: 1% 0% !important;
  }
  .dtl_arr_col {
    max-width: 540px !important;
  }
  .sec_backgpourd_style {
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
  .services_Detail_content {
    width: 1110px !important;
    max-width: 1110px !important;
    gap: 30px !important;
  }
  .services_Detail_content1 {
    width: 1110px !important;
    max-width: 1110px !important;
    gap: 30px !important;
  }
  .services_Detail_content_text {
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
  .dtl_2nd_section {
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
  .dtl_sec2_img {
    width: 100% !important;
  }
  .last_div_care {
    padding: 0px !important;
  }
  .faq_main_otherpages {
    padding: 2% 0% !important;
    width: 1110px !important;
  }
  .faq_main1_otherpages {
    padding: 0% !important;
    width: 1110px !important;
  }
  .home_data {
    width: 1110px !important;
  }
}
