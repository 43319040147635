.contact_para {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  max-width: 339px;
  width: 100%;
  color: #ffffff;
}

.contactform_div {
  width: 88%;
  margin: 0px auto;
}

.contact_botton_div {
  width: 100%;
  text-align: center;
}

.contact_btn {
  width: 105px;
  height: 46px;
  background: #fddb31;
  border-radius: 5px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 13px;
  color: #3c3c3c;
}
.contact_btn:hover {
  background-color: #e2c11c;
}

/* ************************************************************************************************ */

.contact_us {
  max-width: 100%;
  background-color: #f8f9fa;
  margin: auto;
}

@media screen and (min-width: 768px) {
  .contact_us {
    padding: 80px 0px;
  }
  .method_div {
    max-width: 445px;
  }
}

.need_help_heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 118%;
  text-transform: capitalize;
  color: #3c3c3c;
  margin-bottom: 22px;
}

.des_para {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #616161;
  margin-bottom: 0px;
}

.contactUs_anchor {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #fddb31 !important;
  text-decoration: none;
}

.contactUs_imgs {
  height: 20px;
  width: 20px;
  margin-right: 12px;
  margin-left: 22px;
}

.method_div {
  height: 60px;
  background-color: #616161;
  border-radius: 5px;
}

.contactUs_methods {
  gap: 20px;
}

.contactUs_form {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
}

@media screen and (min-width: 1125px) {
  .contactUs_main {
    width: 1110px;
    height: 553px;
    max-width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 540px);
    column-gap: 30px;
  }
}

.contactUs_form {
  grid-row: 1 / 3;
  grid-column: 2;
  padding: 40px 40px 30px;
}

@media screen and (min-width: 992px) and (max-width: 1124px) {
  .contactUs_main {
    width: 980px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
  }
  .contactUs_form {
    grid-row: 1 / 3;
    grid-column: 2;
    padding: 40px 40px 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .contactUs_main {
    height: 553px;
    width: 750px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
  }
  .contactUs_form {
    grid-row: 1 / 3;
    grid-column: 2;
    padding: 20px 20px 30px;
  }
  .need_help_heading {
    font-size: 36px;
  }
  .des_para {
    font-size: 18px;
  }
}

@media screen and (min-width: 575px) and (max-width: 767px) {
  .contact_us {
    padding-top: 21px;
  }
  .contactUs_main {
    display: block;
    width: 550px;
    max-width: 100%;
    margin: auto;
  }
  .contactUs_mob_main {
    width: 550px;
    max-width: 100%;
    margin: auto;
  }
  .contactUs_mob {
    margin-top: 27px;
    width: 100%;
    background-color: #ffffff;
    padding: 30px 0px 60px;
  }
  .method_div {
    width: 550px;
  }
  .need_help_heading {
    font-size: 36px;
    margin-bottom: 10px;
  }
  .des_para {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 425px) and (max-width: 574px) {
  .contact_us {
    padding-top: 21px;
  }
  .contactUs_main {
    width: 410px;
    max-width: 100%;
    margin: auto;
  }
  .contactUs_mob_main {
    width: 410px;
    max-width: 100%;
    margin: auto;
  }
  .contactUs_mob {
    margin-top: 27px;
    width: 100%;
    background-color: #ffffff;
    padding: 30px 0px 60px;
  }

  .need_help_heading {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .des_para {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .contactUs_form {
    padding: 20px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .contact_us {
    padding-top: 21px;
  }
  .contactUs_main {
    width: 360px;
    max-width: 100%;
    margin: auto;
  }
  .contactUs_mob_main {
    width: 360px;
    max-width: 100%;
    margin: auto;
  }
  .contactUs_mob {
    margin-top: 27px;
    width: 100%;
    background-color: #ffffff;
    padding: 30px 0px 60px;
  }
  .method_div {
  }
  .need_help_heading {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .des_para {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .contactUs_form {
    padding: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .contact_us {
    padding-top: 21px;
  }
  .contactUs_main {
    width: 310px;
    max-width: 100%;
    margin: auto;
  }
  .contactUs_mob_main {
    width: 310px;
    max-width: 100%;
    margin: auto;
  }
  .contactUs_mob {
    margin-top: 27px;
    width: 100%;
    background-color: #ffffff;
    padding: 30px 0px 60px;
  }
  .method_div {
    width: 310px;
  }
  .need_help_heading {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .des_para {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .contactUs_form {
    padding: 20px 10px;
  }
  .contactUs_imgs {
    margin-right: 6px;
    margin-left: 12px;
  }
}

@media screen and (max-width: 767px) {
  .contactUs_form {
    padding-bottom: 81px;
  }
}
