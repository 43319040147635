/* ================ home introduction started ============== */

.home__introduction div {
  text-align: center;
  font-size: 2vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
  padding: 0px 7vw 0px 7vw;
  font-weight: 600;
}

.home__introductionImages img:hover {
  transform: scale(1.1);
}

.home__introductionImages {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0px;
  row-gap: 10px;
  padding: 5% 3%;
}
.home__introduction div {
  font-size: 6vw;
  margin-bottom: 10px;
  padding: 0%;
}

._2R-kh {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0px;
  row-gap: 0em;
  padding: 0% 5% 0% 5%;
}

.insta_head {
  text-align: center;
  font-size: 5vw;
  font-weight: 600;
  text-transform: uppercase;
}
.home__introductionImages img {
  max-width: 199px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 2% 4%;
}
.home__introductionImages_destop {
  display: none;
}
@media only screen and (min-width: 600px) {
  .home__introductionImages_destop img {
    max-width: 392px;
    width: 100%;
    margin: 0px auto;
    padding: 2% 1%;
  }
  .home__introductionImages_destop {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0px;
    row-gap: 10px;
    padding: 5% 3%;
  }

  .home__introductionImages_destop img {
    max-width: 392px;
    width: 100%;
    margin: 0px auto;
    padding: 2% 1%;
    height: 100%;
  }
  .insta_head {
    font-weight: 700;
    font-size: 30px;
    line-height: 28px;
    margin-top: 3%;
    color: #3c3c3c;
    margin: 2% 0px;
  }
  .home__introductionImages {
    display: none !important;
  }
  .home__introductionImages_destop {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0px;
    row-gap: 20px;
    padding-top: 4%;
    width: 80%;
    max-width: 1600px;
    margin: 0px auto;
  }
}
@media only screen and (min-width: 1100px) {
  .home__introductionImages_destop img {
    max-width: 392px;
    width: 100%;
    margin: 0px auto;
    padding: 2% 1%;
  }
  .insta_head {
    font-weight: 700;
    font-size: 48px;
    line-height: 28px;
    margin-top: 4%;
    color: #3c3c3c;
  }
  .home__introductionImages_destop {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0px;
    row-gap: 20px;
    padding-top: 2%;
    width: 80%;
    max-width: 1600px;
    margin: 0px auto;
  }
}
